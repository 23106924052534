body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.login-body-bg {
  /* background: url(img/BackgroungLogin.jpg) no-repeat; */
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

#root > div {
  height: 100%;
}

.cust-height {
  height: calc(100% - 70px);
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

.store-tabs .tab-content {
  border: 1px solid #f3f3f3;
  /* padding: 25px; */
  border-radius: 15px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #f3f3f3 #f3f3f3 #fff;
}

.store-tabs .tab-content img {
  background: #f3f3f3;
}

.store-tabs .nav-tabs {
  border-bottom: 0px;
  padding: 0 17px;
}

.store-tabs .nav-tabs .nav-link {
  font-size: 20px;
  margin-right: 6px;
  font-weight: 600;
  color: #b00122;
}

/* .store-tabs .nav-tabs .nav-link.active {
  color: #737275;
} */

.navbar-brand img {
  width: 320px;
}

.pagination li:first-child {
  margin-left: auto;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #f16278;
  border-color: #f16278;
}

.page-link {
  color: #f16278;
}

.page-link:hover {
  color: #f16278;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

.back-btn {
  position: absolute;
  margin-top: 25px;
  z-index: 9;
}

.journal-title {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.journal-title:hover {
  text-decoration: none;
  color: #f16278;
}

.journalsubtitle {
  margin: 8px 0;
  line-height: 18px;
  font-size: 16px;
  padding: 0;
}

.issn-code {
  margin: 10% 0 5% 0;
}

.table-bordered td {
  padding: 5px 10px !important;
  vertical-align: middle !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 22px;
  margin-top: -17px;
}

.svg-inline--fa.fa-power-off {
  color: #bf1c20;
  opacity: 0.5;
  transition: 0.5s;
}

.svg-inline--fa.fa-power-off:hover {
  color: #bf1c20;
  opacity: 1;
}

.footer-bottom {
  background: #bf1c20;
  padding: 5px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
}

.footer-bottom a {
  color: #fff;
}

.rounded-xl {
  border-radius: 1rem;
}

.center-pic {
  border-radius: 5px;
  max-width: 360px;
}

.fix-bottombtn {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 564px) {
  .store-tabs .tab-content img {
    width: 100% !important;
  }
  .store-tabs .nav-tabs .nav-link {
    padding: 0.3rem;
    font-size: 14px;
  }
}

button.rs-play {
  cursor: pointer !important;
  pointer-events: all !important;
  outline: none !important;
  background-color: #d02d2d !important;
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: 6px !important;
  color: white !important;
}

.pagination-block {
  position: absolute;
  right: 19px;
  top: -45px;
}

.pagination-block .page-link {
  color: #f16278;
}

.pagination-block .page-link:hover {
  z-index: 2;
  color: #f16278;
  text-decoration: none;
  background-color: #ffe5e9;
  border-color: #f16278;
}

.otplogin {
  color: #f16278;
  text-decoration: none;
}

.otplogin:hover {
  color: white;
  text-decoration: none;
}

.otplogin:focus {
  color: white;
  text-decoration: none;
}

.pagination-block .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #f16278;
  border-color: #f16278;
}

.pagination-block .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

.dashboard-mainpic {
  position: absolute;
  left: 0;
  width: 100%;
  object-fit: cover;
}

.txt-speach {
  background: #f16278;
  display: inline-block;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: uppercase;
  text-shadow: 0 0 2px black;
}

.loder-block {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 59%);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.spinner-border {
  width: 9rem !important;
  height: 9rem !important;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.top-head {
  font-size: 1.5em;
  text-align: center;
  margin: 32px 0 !important;
}

.margin-top--small > div {
  justify-content: center;
}

.orborder {
  position: relative;
  margin: 30px 0;
  padding: 0;
}

.orborder:after {
  background: #e4e4e4;
  height: 2px;
  width: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 20px;
  z-index: 1;
}

.orborder span {
  background: #fff;
  padding: 5px 10px;
  display: inline-block;
  position: relative;
  z-index: 2;
}

/* ----------- Non-Retina Screens ----------- */

@media only screen and (max-device-width: 568px) {
  .brand {
    width: 70%;
  }
  .navbar-brand {
    float: left;
  }
  .hamburg {
    float: right;
  }
  .tab-content {
    margin-bottom: 60px;
  }
  .back-btn {
    margin: 0 auto 20px 20px;
    position: absolute;
  }
  .text-primary {
    padding-top: 10px;
  }
  .table-bordered thead tr th:nth-child(2) {
    width: 30% !important;
  }
  .fCont {
    text-align: center !important;
  }
  .margin-top--small {
    text-align: center;
  }
  .inputStyle {
    margin: 0px 5px;
  }
  .bgChangeMobile {
    /* background: hsl(355, 83%, 40%) url(img/elevation.jpg) no-repeat center top !important; */
    background-size: cover;
  }
  .loginBox {
    position: relative !important;
    margin-top: -40% !important;
    padding-bottom: 30px;
  }
  .otpBox {
    position: absolute;
    margin-top: 50% !important;
    width: 94%;
  }
  .profileBox {
    padding: 20px !important;
    margin: 1em !important;
  }
}
