.bg_learner {
  background-image: url('../assets/learner-background.jpg');
  height: calc(100vh - 80px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-card {
  padding-left: 40px;
  padding-right: 40px;
}

.heading-black-text {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.image-box {
  padding: 80px;
  background-color: midnightblue;
}

.sidebar .sidebar-links > a:hover {
  background: #000000 !important;
  color: #ffffff !important;
}

.sidebar .sidebar-links > a[aria-expanded='true'] {
  background: #000000 !important;
}
