.topband1 {
  background: #2E353C;
  color: #ffffff;
  width: 100%;
  padding: 0!important;
  transition: all 0.3s ease-in-out;
  display: flex;
  /* border-bottom: 1px solid #ddd; */
}
/* .navbar {
  display: none;
}
footer {
  display: none;
} */
.userpic {
  background: #eaeaea;
  border: none;
  color: #252b36;
  border-radius: 20px!important;
  min-width: 40px;
  min-height: 40px;
  margin-left: 20px;
}

.logo-container{
  background-color: #ffffff;
  width: 250px;
  display: inline-block;
  height: 81px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.logo-img{
  width: 230px!important;
}

.header-container{
  display: inline-block;
  width: calc(100vw - 290px);
  padding-top:15px;
}


.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.fix-header {
  height: 64px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px rgba(23, 43, 77, 0.04);
}

.header-logo-img {
  height: 40px;
}