body {
  /*    background: linear-gradient(to bottom right, #ffcccc -62%, #ffffff 101%); 
    background: linear-gradient(to bottom, right, center, #fcebeb -62%, #faecec 101%, rgb(112, 19, 19) 50%); */
  background: radial-gradient(white, rgb(253, 243, 240));
}

.sponser_logo {
  display: block;
  float: left;
  height: 100px;
  width: 140px;
}

.trip_up_to {
  margin-top: -30px;
  width: 120px;
}

@media screen and (max-width: 767px) {
  .trip_up_to {
    margin-top: -30px;
    width: 60px;
  } 
}

.vr {
  display: inline-block;
  height: 34px;
  background-color: rgb(168, 164, 164);
  width: 1px;
  margin-left: 4px;
  margin-top: 30px;
}

.knowladgebridge_pic {
  margin-top: -30px;
  display: inline-block;
  padding-left: 4px;
  width: 170px;
}

.right_pic_collection_up {
  display: block;
  float: right;
  padding-right: 40px;
}

.trip_medical {
  font-size: 37px;
  font-weight: 800;
  text-align: center;
  line-height: 35px;
  margin-top: -7px;
  color: rgb(100, 34, 109);
}

.a_smart_fast {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: rgb(41, 190, 190);
  margin-top: -25px;
  letter-spacing: -2px;
}

.middl_big_pic {
  width: 100%;
  height: 50%;
}

.img-fluid.mobile {
  display: none;
}

.divmargin {
  margin-top: -400px;
}

@media only screen and (max-width: 750px) {
  .middl_big_pic {
    width: 100%;
    height: 40%;
  }

  .divmargin {
    margin-top: 0px;
  }

  .img-fluid {
    display: none;
  }

  .img-fluid.mobile {
    display: block;
  }

  .btn_press {
    height: 7% !important;
  }

  .trip_medical {
    font-size: 37px;
    font-weight: 800;
    text-align: center;
    margin-top: -7px;
    color: rgb(100, 34, 109);
  }

  .right_pic_collection_up {
    display: block;
    float: right;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1025px) {
  .middl_big_pic {
    width: 100%;
    height: 40%;
  }

  .divmargin {
    margin-top: -300px;
  }

  .right_pic_collection_up {
    display: block;
    float: right;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 505px) {
  .a_smart_fast {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: rgb(41, 190, 190);
    margin-top: -25px;
    letter-spacing: -2px;
  }

  .divmargin {
    margin-top: 0px;
  }

  .sponser_logo {
    display: block;
    float: left;
  }

  .knowladgebridge_pic {
    margin-top: -30px;
    display: inline-block;
    width: 60px;
  }
}

.btn_press {
  position: relative;
  margin-top: -64px;
  margin-left: 70.3%;
  border-radius: 50%;
  height: 9%;
  width: 6%;
}

.brought_you {
  background-color: rgb(170, 106, 179);
  color: white;
  text-align: center;
  border-radius: 10px;
  padding-bottom: 2px;
  font-weight: 500;
}

.pram_brand_1234 span {
  padding-left: 10px;
  font-size: 23px;
  font-weight: 600;
  color: rgb(119, 118, 118);
}

@media only screen and (min-width: 600px) {
  .v1_footer {
    margin-left: 20px;
    border-left: 2px dotted rgb(183, 185, 183);
    height: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .v1_footer {
    /* margin-left: 20px; */
    border-bottom: 2px dotted rgb(183, 185, 183);
    height: 1px;
    width: 80px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.loginBoxResp {
  position: absolute;
  top: calc(100vw * 0.1);
  right: 2%;
  width: 30%;
}

@media only screen and (min-width: 1400px) {
  .loginBoxResp {
    position: absolute;
    top: calc(100vw * 0.05);
    right: 2%;
    width: 30%;
    z-index: 99;
  }

  .login-submit-btn img{
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .loginBoxResp {
    position: absolute;
    top: calc(100vw * 0.05);
    right: 2%;
    width: 30%;
    z-index: 99;
  }
  .login-submit-btn img{
    width: 80%;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1199px) {
  .loginBoxResp {
    position: absolute;
    top: calc(100vw * 0.07);
    right: 2%;
    width: 30%;
    z-index: 99;
  }
  .login-submit-btn img{
    width: 80%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .loginBoxResp {
    position: absolute;
    top: calc(100vw * 0.05);
    right: 2%;
    width: 30%;
    z-index: 99;
  }
  .login-submit-btn img{
    width: 100%;
  }
}

@media only screen and (min-width: 850px) and (max-width: 999px) {
  .loginBoxResp {
    position: absolute;
    top: calc(100vw * 0.06);
    right: 2%;
    width: 30%;
    z-index: 99;
  }
  .login-submit-btn img{
    width: 100%;
  }
}

@media only screen and (min-width: 750px) and (max-width: 849px) {
  .loginBoxResp {
    position: absolute;
    top: calc(100vw * 0.07);
    right: 2%;
    width: 30%;
    z-index: 99;
  }
  .login-submit-btn img{
    width: 100%;
  }
}

@media only screen and (min-width: 750px) and (max-width: 999px) {
  .middl_big_pic {
    width: 100%;
    height: 50%;
    min-height: 320px;
    z-index: 99;
  }
}

@media only screen and (min-width: 750px) {
  .forBgScreenOnly {
    display: block;
  }

  .forSmScreenOnly {
    display: none;
  }

  .spaceOnlyDiv {
    height: 120px;
  }

}

@media only screen and (max-width: 749px) {
  .forBgScreenOnly {
    display: none;
  }

  .forSmScreenOnly {
    display: block;
  }

  .spaceOnlyDiv {
    height: 0px;
  }
}

.loginFormSmall {
  padding: 10px;
}


.anchorTagBtns,
.anchorTagBtns:hover {
  color: #543767;
  text-decoration: none;
  white-space: nowrap;
}

.landing-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-btn-div button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #543767;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  width: 180px;
  height: 60px;
  margin-top: 50px;
  border-radius: 5px;
  border: 1px solid #543767;
}

.landing-btn-div button:hover {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #543767;
  border: 1px solid #543767;
  background-color: #ffffff;
  cursor: pointer;
  text-align: center;
  width: 180px;
  height: 60px;
  margin-top: 50px;
  border-radius: 5px;
  font-weight: bold;
}

.home-tab-bar{
  margin: 0px;
  display: none!important;
}

@media only screen and (max-width: 749px) {
  .home-tab-bar{
    margin: 0px;
    display: flex!important;
  }
}



.home-tab-bar .col{
  padding: 0px!important;
}

.home-tab-bar .tab{
  /* background-color: #f5f5f5; */
}

.home-tab-bar .tab > button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdecf4;
  color: #543767;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 60px;
  border-radius: 25px 25px 0px 0px;
  border: 1px solid #fdecf4;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home-tab-bar .tab.active > button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid #543767;
  border-bottom: 0px;
  background-color: #7a588c;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 60px;
  border-radius: 15px 15px 0px 0px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home-tab-bar .tab > button:hover {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid #543767;
  border-bottom: 0px;
  background-color: #543767;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 60px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* font-weight: bold; */
}

@media screen and (max-width: 767px) {
  .home-tab-bar .tab > button,
  .home-tab-bar .tab > button:hover, 
  .home-tab-bar .tab.active > button {
    font-size: 10px;
    padding-left: 2px;
    padding-right: 2px;
    width: calc(100% - 4px);
  }
}

area{
  cursor: pointer;
}


.reset-box{
  padding: 15px;
  margin-top: 15px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.first-half{
  height: 50px;
}

.first-half .logo-text{
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  text-align: center;    
}

.first-half .login-text{
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}

.login-box-form > .label{
  color: #000000;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 15px;
}