.right-block {
  padding-left: 250px;
  margin: 12px;
}

.select-bottom-space{
  margin-bottom: 16px;
}

.center-align-text{
  text-align: center;
}

.updown-space{
  margin: 15px 0px 15px 0px
}