.ck-editor__editable_inline{
    height: 300px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
}

.fix-header {
    height: 80px!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
}

.header-logo-img {
    height: 70px;
}

.navigation {
    float: right;
}

.navigation ul li {
    padding: 22px 0;
    color: #4c228e;
    font-size: 16px;
    margin-left: 40px;
    font-weight: 600;
    float: left;
    list-style: none;
}

.webcam-div-modal{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
}

.webcam-div{
    position:absolute;
    top:calc(40vh - 80px);
    left:calc(50vw - 175px);
    z-index: 9999;
    background-color:#ffffff;
    padding:15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 350px;
}

.navigation ul li:hover>a {
    color: #4caf50 !important;
}

.signinbtn {
    margin-top: -10px;
    background: #4c228e;
    padding: 8px 30px !important;
    border-radius: 50px;
    color: #fff;
    border-width: 0;
}

.signinbtn:hover {
    background: #4caf50;
    padding: 8px 30px !important;
    border-radius: 50px;
    color: #ffffff !important;
    border-width: 0;
    margin-top: -10px;
}

.bg-dark-blue {
    background-color: #003865 !important;
}

.sidebar1 .sidebar-links > a:hover {
    background: none!important;
    background-color: #4caf50!important;
    color: #ffffff!important;
}

.sidebar1 .sidebar-links > a[aria-expanded=true] {
    background: none!important;
    background-color: #4caf50!important;
    color: #ffffff!important;
}

.sidebar1 .sidebar-links .subMenu {
    background: #ffffff!important;
    padding: 20px;
}

.sidebar1 .sidebar-links .subMenu > a {
    /* color: #ffffff;
    padding: 5px 0; */
    color: #333!important;
    line-height: 1.25;
    font-size: 13px;
    /* font-weight: bold; */
}

.bg-light-blue {
    background: #e4f5fc !important;
}

.height-header-minus {
    height: calc(100vh - 80px);
}

.flex {
    display: flex;
}

.green-gradiant {
    background: rgb(0, 157, 224);
    background: linear-gradient(45deg, rgba(0, 157, 224, 1) 0%, rgba(0, 172, 65, 1) 100%) !important;
}

.pink-gradiant {
    background: rgb(0, 157, 224);
    background: linear-gradient(45deg, rgba(0, 157, 224, 1) 0%, #ee3d8b 100%) !important;
}

.text-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.text-white {
    color: #ffffff !important;
}

.text-bold {
    font-weight: 600;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem;
}

.col-blue {
    color: #2aade3 !important;
}

.heading-1 {
    font-size: 24px !important;
}

.heading-2 {
    font-size: 18px !important;
}

.heading-3 {
    font-size: 15px !important;
}

.section-400 {
    height: 400px;
    padding-top: 80px;
}

.section-300 {
    height: 300px;
    padding-top: 60px;
}

.home-card {
    padding: 15px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    height: 350px;
    position: relative;
}

.bg-white {
    background-color: #ffffff;
}

.home-card-heading {
    font-size: 18px;
    margin-bottom: 5px;
    color: #003865;
    font-weight: 500;
}

.home-card-subheading {
    font-size: 15px;
    margin-bottom: 5px;
    color: #666666;
    font-weight: 400;
}

.home-card-listing {
    font-size: 13px;
    color: #666666;
    margin-bottom: 5px;
}

.card-action-btn {
    all: unset;
    color: #ffffff;
    border: 1px solid #4caf50;
    background-color: #4caf50;
    cursor: pointer;
    width: calc(100% - 30px);
    text-align: center;
    padding: 5px 0px;
    position: absolute;
    bottom: 10px;
}

.card-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.card-icon {
    font-size: 24px;
    color: #4caf50;
}

.icon-bg {
    background-color: #a6d8a8;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-1-minus-margin {
    margin-top: -180px;
}

.text-dark {
    color: #333;
    line-height: 1.25;
    font-weight: bold;
}

.btn-blue {
    color: #fff;
    background: #009de0;
    border: none;
}

.text-blue {
    color: #009de0 !important;
}

.bg-lightblue {
    background-color: #80d9ff;
}

.carousel-3-card {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}

.carousel-card-label {
    font-size: 14px;
    color: #ffffff;
}

.carousel-card-value {
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0px;
}

.integration-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    overflow: hidden;
}

.green-anchor-tag {
    color: #4caf50;
    font-size: 12px;
    font-weight: bold;
}

.transparent-card-img {
    height: 150px;
}

.top-bottom-footer-space {
    padding-top: 60px;
    padding-bottom: 60px;
}

.footer-header {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
}

.footer-link,
.footer-link:hover {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
}

.fixed-whitebox-bottom {
    position: absolute;
    height: calc((100vh - 80px)/2);
    bottom: 0px;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;
}

.pos-relative {
    position: relative;
}

.login-box-div {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    background-color: #ffffff;
    padding: 50px 30px;
    border-radius: 10px;
    z-index: 99;
    width: 100%;
}

.primary-btn {
    background: #71c174;
    padding: 8px 30px !important;
    border-radius: 50px;
    color: #fff;
    border-width: 0;
}

.primary-btn:hover {
    background: #4caf50;
    padding: 8px 30px !important;
    border-radius: 50px;
    color: #ffffff !important;
    border-width: 0;
}

.dashboard-card-box {
    padding: 15px;
    box-shadow: 0px 0px 9px rgba(151, 151, 155, 0.15);
    background-color: #ffffff;
    height: 210px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dashboard-card-only{
    padding: 15px;
    background: #FFFFFF;
    /* Elevation/Cards */
    box-shadow: 0px 0px 9px rgba(151, 151, 155, 0.15);
}

.dashboard-card-number{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    color: #1b1b1b;
}

.dashboard-card-label{
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
    color: #1b1b1b;
}

.dashboard-card-fix-bottom{
    position: absolute;
    bottom: 0px;
    width: 100%;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-left: -15px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .main-sidebar{
    background-color: ;
} */

/* New SideBar */

.new-sidebar-panel{
    display: flex;
    /* height: calc(100vh - 80px); */
    background-color: #ffffff;
}

.main-sidebar{
    width: 75px;
    box-shadow: 1px 0px 0px rgba(223, 225, 230, 0.5);
}

.main-sidebar-btn{
    all: unset;
    cursor: pointer;
    color: #5d6a79;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 10px 0px;
    border-left: 5px solid #F9FAFF;
    
}

.main-sidebar-btn.active{
    background-color: #fef8f6;
    color: #f09475;
    border-left: 5px solid #f09475;
}

.main-sidebar-btn > p{
    font-size: 10px;
    color: #5d6a79;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 5px;
}

.main-sidebar-btn.active > p{
    color: #f09475;
}

.submenu-sidebar{
    overflow:hidden;
    width: 0px;
    background-color: #F9FAFF;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    padding: 5px 0px;
    position: relative;
}

.submenu-sidebar.active{
    width: 200px;
}

.submenu-sidebar > a {
    font-size: 12px;
    display: block;
    margin:5px;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    min-height: 56px;
    color: #333333;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.submenu-sidebar > a:hover {
    font-size: 12px;
    display: block;
    margin:5px;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    min-height: 56px;
    color: #f09475!important;
    display: flex;
    align-items: center;
    border-left: 5px solid #f09475;
    text-decoration: none!important;
}

.sidebar-close-btn{
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    color: #333333;
}

/* Admin Section CSS */
a.admin-action-btns, a.admin-action-btns:hover, a.admin-action-btns:active, a.admin-action-btns:visited{
    all: unset!important;
    color: #ffffff!important;
    padding: 8px 10px!important;
    cursor: pointer!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    background-color: transparent!important;
    background-color: #004680!important;
    margin-left: 5px!important;
}

button.admin-action-btns{
    all: unset!important;
    color: #ffffff!important;
    padding: 7px 10px!important;
    cursor: pointer!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    background-color: #004680!important;
    margin-left: 5px!important;
}

.admin-action-btns > .icon{
    margin-right: 10px;
}

.admin-page-title{
    font-size: 18px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: bold;
}

.admin-filter-box{
    background: #EFF1FF;
    box-shadow: inset 0px -1px 0px #F4F4F4, inset 0px 1px 0px #F4F4F4;
    border-radius: 5px 5px 0px 0px;
    padding: 15px;
    margin-bottom: 10px;
}

.admin-filter-box label{
    color: #333333;
    font-size: 14px;
}

.admin-filter-box h4{
    color: #585858;
    font-size: 18px;
    font-weight: 700;
}

.admin-filter-box button.success{
    all: unset;
    background-color: #4caf50;
    cursor: pointer;
    color: #ffffff;
    font-size: 13px;
    padding: 5px 10px;
    margin: 0px 5px;
}

button.success{
    all: unset;
    background-color: #4caf50;
    cursor: pointer;
    color: #ffffff;
    font-size: 13px;
    padding: 5px 10px;
    margin: 0px 5px;
}

.admin-filter-box button.close{
    all: unset;
    background-color: #ffffff;
    cursor: pointer;
    color: #000000;
    font-size: 13px;
    padding: 5px 10px;
    margin: 0px 5px;
}

.admin-form-row{
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(151, 151, 155, 0.15);
    padding: 20px 10px;
}

.admin-form-row .header{
    /* background-color: #0062b3!important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px; */
    
    
}

.admin-form-row .header h4{
    color: #333333;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.admin-form-row label{
    color: #003865;
    font-size: 13px;
    font-weight: bold;
}

.admin-form-row button.primary{
    all: unset;
    background-color: #4caf50;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0px 5px;
    font-weight: bold;
}

.admin-form-row button.secondary{
    all: unset;
    background-color: #b3b3b3;
    cursor: pointer;
    color: #000000;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0px 5px;
    font-weight: bold;
}

button.secondary{
    all: unset;
    background-color: #b3b3b3;
    cursor: pointer;
    color: #000000;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0px 5px;
    font-weight: bold;
}

#multipleActionBtn, #multipleActionBtn:active, #multipleActionBtn:hover, #multipleActionBtn:focus{
    all: unset;
    background-color: #f5f5f5!important;
    cursor: pointer;
    color: #4caf50!important;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0px 5px;
    font-weight: bold;
}

.multiplebtn-links{
    font-size: 14px;
}

.admin-form-row button.ternary{
    all: unset;
    background-color: #542c93;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0px 5px;
    font-weight: bold;
}

.registerFixButton{
    position: fixed;
    bottom: 50px;
    right: 70px;
    height: 70px;
    width: 70px;
    border-radius: 35px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer!important;
}

.registerFixButton p{
    margin-bottom: 0px;
    font-size: 10px;
    text-align: center;
    color: #000000;
    font-weight: bold;
}

.signup-icon{
    width: 30px;
    height: 30px;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
}

.rotate{
    transform: rotate(45deg);
}

.fixRegisterForm{
    width: 300px;
    position: fixed;
    bottom: 125px;
    right: 70px;
    height: 0px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    cursor: pointer;
}

.fixRegisterForm.active{
    height: 500px;
}

.fixRegisterForm > .header{
    padding: 10px 15px;
    color: #ffffff; 
}

.registerLabel{
    font-size: 13px;
    color: #000000;
    margin-bottom: 0px;
}

.required{
    color: #ff0000;
}

/* Profile Page */
.profile-sidebar{
    height: calc(100vh - 80px);

}

.profile-content-section{
    /* padding: 15px;
    background-color: #f5f5f5;
    margin: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgb(151 151 155 / 15%);
    padding: 20px 10px;
}

.profile-form-label{
    margin-bottom: 0px;
    font-size: 13px;
    color: #000000;
}

.profile-dp-div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    margin-top: 50px;
    position: relative;
}

.dp-edit-btn{
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-dp-div img{
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border-radius: 50px;
} 

.profile-name{
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.profile-email{
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.filterList label{
    font-size: 12px;
    color: #000000;
}

.grayLeftSide{
    background-color: #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.toggle-btn-div{
    display: flex;
}

.toggle-btn-div > button.toggle-btn{
    all: unset;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    cursor: pointer;
}

.toggle-btn-div > .toggle-btn.active{
    background-color: #4c228e;
    color: #ffffff;
}

.file-drop{
    border: 1px solid #ccc;
}

.file-drop-target{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 150px;
    background-color: #f5f5f5;
}

.file-drop .placeholder1{
    margin-bottom: 0px;
    text-align: center;
    position: absolute;
    font-size: 14px;
}

.table-dropdown{
    width: 100px;
    /* height: 250px; */
    position: absolute;
    top: 40px;
    right: 38px;
    background-color:#ccc;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-option-button{
    all: unset;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 15px);
    padding-left: 15px;
    border-bottom: 1px solid #ccc;
}

.modal-option-button:hover{
    background-color: #4caf50;
    color: #ffffff;
}

.labelCol{
   display: flex;
   align-items: center; 
}

.formRow{
    margin-top: 10px!important;
}

.tabviewBtn{
    all:unset;
    cursor: pointer;
    border: 1px solid #000000;
    padding: 5px 15px;
    width: 150px;
    text-align: center;
    color: #000000;
    font-size: 15px;
}

.tabviewBtn.active{
    all:unset;
    cursor: pointer;
    border: 1px solid #4caf50;
    padding: 5px 15px;
    width: 150px;
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    background-color: #4caf50;
}

